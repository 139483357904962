import React, {useEffect} from 'react'
import {graphql} from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Share from '../../components/share'
import styled from 'styled-components'
import Modal from '../../components/modal'
import FreeProductOffer from '../../components/FreeProductOffer'
import {Link} from 'gatsby'

export default function BlogPostTemplate({data, location}) {
  const {markdownRemark} = data
  const {frontmatter, html} = markdownRemark
  const {title, iFrameSrc} = frontmatter
  const {href} = location
  const allArticles = data.allArticles.nodes

  const BoxWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 80px;

    @media (max-width: 768px) {
      flex-direction: column;
      margin: auto;
    }
  `

  const BoxContainer = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
  `

  const FrontDiv = styled.div`
    position: absolute;
    flex-direction: column;
    display: flex;
    border-radius: 8px;
    border: 3px solid black;
    width: 100%;
    height: 100%;
    z-index: 2;
    transform: translateZ(50px);
    align-items: center;
    justify-content: center;
  `

  const BackDiv = styled.div`
    position: absolute;
    border-radius: 8px;
    border: 3px solid black;
    top: 10px;
    left: 10px;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: white;
  `

  useEffect(() => {
    if (window.YT) {
      onYouTubeIframeAPIReady()
    } else {
      // Load YouTube Iframe API
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady
    }
  }, [])

  const onYouTubeIframeAPIReady = () => {
    const player = new window.YT.Player('youtube-video', {
      events: {
        onStateChange: onPlayerStateChange,
      },
    })
  }

  const onPlayerStateChange = event => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      syncLyrics(event.target)
    }
  }

  const syncLyrics = player => {
    const lyricsLines = document.querySelectorAll('.lyrics-line')
    const updateLyrics = () => {
      const currentTime = player.getCurrentTime()
      lyricsLines.forEach(line => {
        const lineTime = parseFloat(line.getAttribute('data-time'))

        if (currentTime >= lineTime) {
          lyricsLines.forEach(l => l.classList.remove('highlight'))
          line.classList.add('highlight')
        }
      })
      requestAnimationFrame(updateLyrics)
    }
    updateLyrics()
  }

  return (
    <Layout>
      <SEO
        title={frontmatter.metaTitle}
        description={frontmatter.metaDescription}
        hasJsonLd
        article
        image={frontmatter?.featuredImage}
      />
      <Modal />
      <div className="wrapper">
        <Share redirect={href} title={title} isVertical />
        <section className="section is-medium">
          <h1 className="title has-text-centered">{title}</h1>

          {iFrameSrc && (
            <BoxWrapper>
              <BoxContainer>
                <FrontDiv>
                  <iframe
                    id="youtube-video"
                    width="100%"
                    height="400"
                    src={`${iFrameSrc}?rel=0&enablejsapi=1`}
                    frameBorder="0"
                    allowFullScreen
                  />
                </FrontDiv>
                <BackDiv></BackDiv>
              </BoxContainer>
            </BoxWrapper>
          )}

          <br />
          <br />
          <br />
          <div className="content" dangerouslySetInnerHTML={{__html: html}} />
          <Share title={title} redirect={href} />
          <section style={{padding: 20}} className="box has-background-white">
            <FreeProductOffer
              image={data.CommunityImage.childImageSharp.fluid}
              dataTarget="FreeProductModalBlog"
              modalId="FreeProductModalBlog"
              formSrc="https://e7b4fa4e.sibforms.com/serve/MUIFAOwMzjN-YKMeTwYotO1Oo-5NmGfEcf0uXOWdeSE_zbzlMgOFVcqpsGrKyB6HNpcxv1QyWNUqtIU3qUFLY2naQC30o3DO5hzuSyFP_jG8OJUv-Q2ASBbmaRfsBn-FUW1nJbmRZuL6CZNXVYbHE54Bn_pif8clX0tPyWX_9la3C6S7GUItJ04UX_g-P3NuShqSj2g3JuB6YiKL"
              description={`Aprende más. Sea notificado cuando se publiquen nuevas letras`}
              cta="¡Únete ahora!"
              ctaIsLink
            />
          </section>

          {/* Suggestion Cards Section */}
          <section className="section is-small">
            <h2 className="title has-text-centered">Artículos Relacionados</h2>
            <div className="columns is-multiline">
              {allArticles.map(article => (
                <div key={article.id} className="column is-one-third">
                  <Link to={`/blog/${article.frontmatter.slug}`}>
                    <div style={{minHeight: 355}} className="card">
                      <div className="card-image">
                        {article.frontmatter.image && (
                          <figure className="image is-4by3">
                            <img
                              style={{
                                objectFit: 'cover',
                                height: '10em',
                              }}
                              src={article.frontmatter.image}
                              alt={article.frontmatter.title}
                            />
                          </figure>
                        )}
                      </div>
                      <div className="card-content">
                        <p className="title is-5">
                          {article.frontmatter.title}
                        </p>
                        <p className="subtitle is-6">
                          {article.frontmatter.date}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </section>
        </section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    allArticles: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {id: {ne: $id}}
      limit: 6
    ) {
      nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          slug
          image
        }
      }
    }
    markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        metaTitle
        metaDescription
        iFrameSrc
        featuredImage
        image
      }
    }
    images: allFile(
      filter: {relativeDirectory: {eq: "index"}}
      sort: {fields: name}
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    FreeProductImage: file(name: {eq: "FreeProductImage"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    CommunityImage: file(name: {eq: "CommunityImage"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
